import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { ButtonReset, Tag, media } from '../ui';
import { PrismicRichText } from '@prismicio/react';
import { motion } from 'framer-motion';
import TextToSpeechButton from '../components/TextToSpeechButton';

const Card = styled(motion.article)`
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
  border-radius: ${({ theme }) => theme.borderRadius};
  margin-bottom: ${({ theme }) => theme.spacing()};
  padding: ${({ theme }) => theme.spacing()};
  padding-right: 80px;
  min-height: 282px;
  position: relative;
  overflow: hidden;
  background-color: ${({ theme }) => theme.card.background};

  &:after {
    position: absolute;
    bottom: 0;
    height: 16px;
    background: linear-gradient(
      to top,
      ${({ theme }) => theme.card.backgroundAlt} 35%,
      transparent
    );
    left: 0;
    right: 0;
    content: '';
    display: block;
  }

  ${({ variant }) =>
    variant === 'pro' &&
    `
      min-height: 192px;
    `}
`;

const CardTitle = styled.div`
  h3 {
    margin-top: 0;
    margin-bottom: ${({ theme }) => theme.spacing()};
    color: ${({ theme }) => theme.primary};
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 1.2;
  }
`;

const CardContent = styled.div`
  color: ${({ theme }) => theme.text};
  > *:last-child {
    margin-bottom: 0;
  }
`;

const ArticleCardImage = styled.img`
  width: 96px;
  height: 96px;
  object-fit: cover;
  border-radius: ${({ theme }) => theme.borderRadius};

  ${media.tablet`
    width: 160px;
    height: 160px
  `}

  ${({ variant }) =>
    variant !== 'pro' &&
    media.desktop`
      width: 250px;
      height: 250px;
    `}
`;

const ArticleContent = styled.div`
  ${media.tablet`
    display: flex;
  `}
`;

const ArticleImage = styled.div``;

const ArticleBody = styled.div`
  flex: 1;
  ${media.tablet`
    padding-left: ${({ theme }) => theme.spacing()};
  `}
`;

const Button = styled(ButtonReset)`
  height: 48px;
  width: 48px;
  border-radius: 100%;
  background-color: ${({ theme }) => theme.secondary};
  position: absolute;
  bottom: 16px;
  right: 16px;
`;

const WrapperIcon = styled.div`
  position: relative;
`;

const Horizontal = styled(motion.div)`
  position: absolute;
  background-color: #fff;
  width: 20px;
  height: 4px;
  left: 50%;
  margin-left: -10px;
  top: 50%;
  margin-top: -2px;
`;

const Vertical = styled(motion.div)`
  position: absolute;
  background-color: #fff;
  width: 4px;
  height: 20px;
  left: 50%;
  margin-left: -2px;
  top: 50%;
  margin-top: -10px;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ArticleCard = ({ actuality, index, variant }) => {
  const [expanded, setExpanded] = useState(false);
  const cardRef = useRef();

  return (
    <Card
      variant={variant}
      initial={expanded}
      ref={cardRef}
      animate={{
        height: expanded ? '100%' : variant === 'pro' ? 192 : 282,
      }}
      transition={{ ease: 'easeOut', duration: 0.25 }}
    >
      <ArticleContent>
        <ArticleImage>
          <ArticleCardImage
            src={actuality?.thumbnail?.url}
            alt={actuality?.thumbnail?.alt}
            variant={variant}
          />
        </ArticleImage>

        <ArticleBody id={`article-${index}`}>
          <TitleWrapper>
            <CardTitle>
              <PrismicRichText
                field={actuality?.title?.richText ?? actuality?.title}
              />
            </CardTitle>
            {Boolean(actuality?.kind) && (
              <Tag kind={actuality?.kind}>{actuality?.kind}</Tag>
            )}
          </TitleWrapper>

          {variant !== 'pro' && (
            <TextToSpeechButton
              index={index}
              id={`article-${index}`}
              onClick={() => setExpanded(true)}
              shouldShowOverlay={!expanded}
            />
          )}

          <CardContent>
            <PrismicRichText
              field={actuality?.content?.richText ?? actuality?.content}
            />
          </CardContent>
        </ArticleBody>
      </ArticleContent>

      <Button onClick={() => setExpanded(!expanded)}>
        <WrapperIcon>
          <Horizontal
            initial={expanded}
            transition={{ ease: 'easeOut', duration: 0.25 }}
            animate={{
              transform: expanded ? 'rotate(90deg)' : 'rotate(-90deg)',
              opacity: expanded ? 0 : 1,
            }}
          />
          <Vertical
            initial={expanded}
            transition={{ ease: 'easeOut', duration: 0.25 }}
            animate={{
              transform: expanded ? 'rotate(90deg)' : 'rotate(-90deg)',
            }}
          />
        </WrapperIcon>
      </Button>
    </Card>
  );
};

export default ArticleCard;
